<template>
  <main class="kb-main" id="kb-helpdesk-osoa" v-if="!isLoading">
    <lxp-main-header :show-breadcrumb="true" :show-title="true"/>
    <!-- main-content -->
    <div class="main-content min-component">
      <article class="content-section section-margin">
        <header class="section-header header-divider">
          <h3 class="title">MS Office</h3>
        </header>
        <div class="kb-table kb-table-bordered-v2">
          <table>
            <tr>
              <th>운영체제</th>
              <th>버전</th>
              <th>설치 안내</th>
              <th>설치 파일</th>
            </tr>
            <tr>
              <td>Windows</td>
              <td>Office 2021</td>
              <td><file-down-link file-key="Office2021Guide" /></td>
              <td><file-down-link file-key="Office2021Setup64" :click-pre-func="checkInside" /></td>
            </tr>
            <tr>
              <td>Windows</td>
              <td>Office 2019</td>
              <td><file-down-link file-key="Office2019Guide" /></td>
              <td><file-down-link file-key="Office2019Setup64" :click-pre-func="checkInside" /></td>
            </tr>
            <tr>
              <td>Windows</td>
              <td>Office 2016</td>
              <td><file-down-link file-key="Office2016Guide" /></td>
              <td>
                <file-down-link file-key="Office2016Setup64" :click-pre-func="checkInside" />
                <file-down-link file-key="Office2016Setup32" :click-pre-func="checkInside" />
              </td>
            </tr>
            <tr>
              <td>Mac</td>
              <td>Office 2019</td>
              <td><file-down-link file-key="Office2019GuideMAC" /></td>
              <td>
                <file-down-link file-key="Office2019SetupMAC" :click-pre-func="checkInside" />
              </td>
            </tr>
          </table>
        </div>
      </article>
      <article class="content-section">
        <header class="section-header header-divider">
          <h3 class="title">Windows OS</h3>
        </header>
        <div class="kb-table kb-table-bordered-v2">
          <table>
            <tr>
              <th>버전</th>
              <th>설치 안내</th>
              <th>설치 파일</th>
            </tr>
            <tr>
              <td>Windows 11</td>
              <td><file-down-link file-key="Windows11Guide" /></td>
              <td><file-down-link file-key="Windows11Setup64" :click-pre-func="checkInside" /></td>
            </tr>
            <tr>
              <td>Windows 10</td>
              <td><file-down-link file-key="Windows10Guide" /></td>
              <td>
                  <file-down-link file-key="Windows10Setup64" :click-pre-func="checkInside" />
                  <file-down-link file-key="Windows10Setup32" :click-pre-func="checkInside" />
              </td>
            </tr>
          </table>
        </div>
      </article>
      <div class="content-section">
        <div class="section-bottom">
          <span class="notice-icontext">설치 및 인증 관련 문의 | 테크데이타 기술지원센터 전화: 02-3272-7900 (월~금 9:00~18:00, 점심시간 12시~13시), 공휴일 제외</span>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import {ref, onMounted} from "vue";
import navigationUtils from "@/assets/js/navigationUtils";
import LxpMainHeader from '@/components/common/LxpMainHeader';
import FileDownLink from "@/components/common/FileDownLink";
import {useAlert} from "@/assets/js/modules/common/alert";
import {goBack} from "@/assets/js/modules/common/common";
import {getToken} from "@/assets/js/localstorage.service";

export default {
  name: 'HelpOsoa',
  components: {LxpMainHeader, FileDownLink,},
  setup() {
    const isLoading = ref(true);
    const {showMessage} = useAlert();

    const checkInside = () => {
      if('1' === getToken('kbInside')){
        showMessage('은행 내부에서는 설치파일을 다운로드할 수 없습니다.');
        return false;
      }else{
        return true;
      }
    }

    onMounted(() => {
      if(navigationUtils.any()){
        showMessage('모바일에서 접근할 수 없는 페이지입니다.', goBack);
      }else{
        isLoading.value = false;
      }
    })

    return {
      isLoading,
      checkInside,
    }
  }
}
</script>